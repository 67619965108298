import { createStyles, Button, FileInput, Modal, Group } from "@mantine/core";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { IconEdit, IconExclamationCircle, IconCirclePlus } from "@tabler/icons";
import toast from "react-hot-toast";
import _ from "lodash";

import { useServerApi } from "../hooks/userServerApi";
import { useFormRender } from "../hooks/useFormRender";
import PageListHeader from "./pageListHeader";
import DataTable from "./dataTable";

import { Page } from "../layout/page";
import ReactJson from "react-json-view";
import { useLocalStorage } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { functionLinks } from "../data/navLinks";
import { useAuthUser } from "react-auth-kit";
import { useAccessRight } from "../hooks/useAccessRight";

export const ENUM_FORM_DISPLAY = {
  DRAWER: 0,
  MODAL: 1,
  FULLSCREEN: 2,
  INSIDE: 3,
  PAGE: 4,
  LINK: 5,
};

export const ENUM_FORM_LAYOUT_CONTAINER = {
  GRID: 0,
  TABS: 1,
  SECTION: 2,
  WIZARD: 3,
  BOX: 4,
  EMPTY: 5,
};

const handleRegexSpecialChar = (text) => {
  const specialTxt = [
    "\\",
    "^",
    "$",
    ".",
    "|",
    "?",
    "*",
    "+",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
  ];

  let result = text;
  specialTxt.forEach((special) => {
    result = result.replace(special, `\\${special}`);
  });
  return result;
};

export const buildSearchQuery = (searchText, searchFields) => {
  const txt = handleRegexSpecialChar(searchText)?.trim();

  let searchArr = searchFields?.map((field) => ({
    [field]: { $regex: txt, $options: "i" },
  }));
  return { $or: searchArr };
};

export function PageList({
  apiEntity,
  title,
  formSchema,
  tableSchema,
  showAddButton = true,

  hideActionButton = false,
  hideSearchBar = false,
  stats = null,
  filter = null,
  filterQuery = null,
  customActions = null,
  header = null,
  showImport = false,
  reloadAfterSave = false,
  preQueryNotEmpty = false,
  preQuery = null,
  onSubmit,
  onChange,
  searchByTextField = false,
  defaultPageSize = 50,
  readOnly = false,

  initSort = { by: "createdAt", order: "desc" },
  tablePadding = "xl",
  tableHeader,
  importData,
  onImportSuccess,
  onDeleteSuccess,
}) {
  const tableRef = useRef();

  const onSubmitSuccess = () => fetchData();
  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit,
    onChange
  );

  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    totalPage: 1,
    page: 1,
    total: 0,
  });

  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const linkId = query.get("id");
  const linkMode = query.get("mode");
  const linkPath = useLocation().pathname;
  const { t } = useTranslation();

  const [getAccessRight, accessRight, navLinkId] = useAccessRight();

  const auth = useAuthUser();
  const { userRole } = auth();

  const [tQueryStorage, settQueryStorage] = useLocalStorage({
    key: "tableQuery" + linkPath + apiEntity + tableSchema.name,
    defaultValue: {},
  });

  const [pageSize, setPageSize] = useLocalStorage({
    key: "mbid-page-size",
    defaultValue: defaultPageSize,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [tableFilterQuery, setTableFilterQuery] = useState(tQueryStorage);
  const [openedImport, setOpenedImport] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [sort, setSort] = useState(initSort);
  const [api] = useServerApi();

  // Add ResizeObserver error handling
  useEffect(() => {
    const resizeObserverErrorHandler = (error) => {
      if (error.message.includes("ResizeObserver loop")) {
        // Ignore ResizeObserver loop errors
        console.warn("ResizeObserver loop error detected and ignored");
      } else {
        // Log other errors as usual
        console.error(error);
      }
    };

    window.addEventListener("error", resizeObserverErrorHandler);

    return () => {
      window.removeEventListener("error", resizeObserverErrorHandler);
    };
  }, []);

  const buildQuery = () => {
    const searchTextQuery = searchText
      ? {
          searchText: {
            $regex: searchText,
            $options: "i",
          },
        }
      : {};
    const queries = [
      tableSchema.preQuery || preQuery,
      searchByTextField ? searchTextQuery : searchQuery,
      filterQuery,
      tableFilterQuery,
    ].filter((f) => !_.isEmpty(f));

    // console.log("buildQuery", tableSchema.name, apiEntity, queries);
    // console.log(
    //   tableSchema.preQuery,
    //   preQuery,
    //   searchByTextField,
    //   searchTextQuery,
    //   searchQuery,
    //   filterQuery,
    //   tableFilterQuery
    // );
    if (_.isEmpty(queries)) return {};
    return { $and: queries };
  };

  //Fetch Remote Data function
  const fetchData = useCallback(async () => {
    try {
      if (
        preQueryNotEmpty &&
        _.isEmpty(tableSchema.preQuery && _.isEmpty(preQuery))
      ) {
        setRows([]);
        setPagination({
          totalPage: 0,
          page: 1,
          total: 0,
        });
        return;
      }

      // if (apiEntity === "client")
      //   console.log("Fettch", preQueryNotEmpty, tableSchema.preQuery);

      const q = buildQuery();
      let data = await api.search({
        apiEntity,
        pageSize,
        currentPage,
        sort: sort.by ? sort : initSort,
        select: tableSchema.select,
        searchQuery: q,
        searchText,
        searchByTextField,
        preQuery: { ...tableSchema.preQuery, ...preQuery },
      });
      // if (apiEntity === "client") console.log("Page List Fetch Data", q);
      setPagination({
        ...pagination,
        total: data.total,
        totalPage: Math.ceil(data.total / data.limit),
      });
      setRows(data.docs ?? []);
    } catch (error) {
      setRows([]);
      toast.error(`Fetch Error`);
    }
  });

  const onPageChange = useCallback((page) => {
    setCurrentPage(page);
    setPagination({ ...pagination, page });
  });

  const onPageSizeChange = useCallback((pageSize) => {
    console.log("onPageSizeChange", pageSize);
    setCurrentPage(1);
    setPageSize(pageSize);
    setPagination({ ...pagination, page: 1 });
  });

  const onSortChange = useCallback((by, reverseSortDirection) => {
    if (!by) return;
    setSort({ by, order: reverseSortDirection ? "desc" : "asc" });
  });

  const onSearchChange = useCallback((searchText) => {
    let text = searchText;
    if (currentPage > 1) {
      setCurrentPage(1);
      setPagination({ ...pagination, page: 1 });
    }
    setSearchText(text);
  });

  const onTableFilterChange = useCallback((field, values, possibleValues) => {
    // console.log("onTableFilterChange", field, values, possibleValues);
    if (!field) return;
    const newFilterQuery = { ...tableFilterQuery };

    //Empty selected
    if (values.length === 0) {
      newFilterQuery[field] = null;
      setCurrentPage(1);
      setTableFilterQuery(newFilterQuery);
      settQueryStorage(newFilterQuery);
      return;
    }

    //All selected
    if (
      values &&
      possibleValues &&
      values.length === possibleValues.length &&
      newFilterQuery[field]
    ) {
      delete newFilterQuery[field];
      setCurrentPage(1);
      setTableFilterQuery(newFilterQuery);
      settQueryStorage(newFilterQuery);
      return;
    }
    //Partial selected
    newFilterQuery[field] = {
      $in: values,
    };
    setCurrentPage(1);
    setTableFilterQuery(newFilterQuery);
    settQueryStorage(newFilterQuery);
    // console.log("newFilterQuery", newFilterQuery);
  });

  useEffect(() => {
    let query =
      searchText === ""
        ? {}
        : buildSearchQuery(searchText, tableSchema.searchableFields);
    setSearchQuery(query);
  }, [searchText]);

  useDeepCompareEffect(() => {
    if (_.isEmpty(tQueryStorage)) return;
    // if (_.isEmpty(tableFilterQuery)) return;
    // if (_.isEqual(tQueryStorage, tableFilterQuery)) return;
    setTableFilterQuery(tQueryStorage);
  }, [tQueryStorage]);

  useEffect(() => {
    // console.log(sort);
    fetchData();
  }, [
    searchQuery,
    pageSize,
    sort,
    currentPage,
    tableSchema.preQuery,
    preQuery,
    filterQuery,
    tableFilterQuery,
  ]);

  const copyToNew = async (data) => {
    let newData = await api.copyById({
      apiEntity,
      id: data._id,
      newCode: true,
    });

    return newData;
  };

  const onActionBtnClick = useCallback(async ({ action, data }) => {
    // console.log("onActionBtnClick", action, data)
    if (tableSchema.onActionBtnClickCustom) {
      tableSchema.onActionBtnClickCustom({
        action,
        data,
        formAction,
        fetchData,
      });
      return;
    }
    switch (action) {
      case "show":
        formAction.open({ mode: "show", id: data._id });
        break;
      case "edit":
        // console.log("location edit", `${location.pathname}?id=${data._id}`);
        // formAction.openIdByLink({ mode: "edit", id: data._id });
        formAction.open({ mode: "edit", id: data._id });
        break;
      case "copy":
        const newRecord = await copyToNew(data);
        formAction.open({ mode: "edit", id: newRecord._id });
        break;
      case "delete":
        // console.log("delete", data._id);
        if (window.confirm("Are you sure to delete this data?")) {
          await api.removeById({ apiEntity, id: data._id });
          fetchData();
          toast.success("Deleted Successfully");
          if (onDeleteSuccess) onDeleteSuccess();
        }
        break;
      case "unDo":
        console.log("unDo", data);
        break;
    }
  });

  const getTitle = () => {
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return t(title);
    if (!formStatus.isOpen) return t(title);
    return formAction.getTitle();
  };

  const showTable = () => {
    // if (formStatus.mode == "add" || formStatus.mode == "edit") return false
    if (!formStatus.isOpen) return true;

    // if (formSetting.displayMode === ENUM_FORM_DISPLAY.MODAL) return false
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return true;

    return false;
  };

  const getPageListAction = () => {
    const actions = [];
    if (showAddButton && !readOnly && accessRight === 15)
      actions.push({
        label: t("Add New"),
        onClick: () => formAction.open({ mode: "add" }),
      });

    if (showImport && !readOnly && accessRight === 15)
      actions.push({
        label: t("Import"),
        onClick: () => setOpenedImport(true),
        variant: "default",
      });

    actions.push({
      label: t("Refresh"),
      onClick: () => fetchData(),
      variant: "default",
    });
    // console.log("actions", actions);
    return actions;
  };

  const getPageFormAction = () => {
    const actions = [];
    if (!readOnly && accessRight === 15)
      actions.push({ label: t("Save"), onClick: () => formAction.save() });

    actions.push({
      label: t("Close"),
      onClick: () => {
        formAction.close();
        fetchData();
      },
      variant: "default",
    });
    return actions;
  };

  const pageActionButtons = () => {
    if (!formStatus.isOpen) return getPageListAction();
    if (
      formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
      formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
    )
      return getPageListAction();

    return getPageFormAction();
  };

  const handleImport = async () => {
    // console.log("handleIMport", importFile);
    try {
      if (!importFile) return;
      setImporting(true);
      const result = await api.importBatch({
        apiEntity,
        file: importFile,
        importData,
      });

      if (!result.success) {
        // console.log(result);
        throw result.error;
      }
      showNotification({
        title: `${importFile.name} Import successfully`,
        message: "Import Success",
      });
      setOpenedImport(false);
      setImporting(false);
      fetchData();
      if (onImportSuccess) onImportSuccess();
    } catch (error) {
      console.log(error);
      showNotification({
        title: `${importFile.name} Import fail`,
        icon: <IconExclamationCircle size={18} />,
        color: "red",
        message: error,
      });
      setOpenedImport(false);
      setImporting(false);
      console.log(error);
    }
  };

  return (
    <Page>
      {/* navLinkId: {navLinkId} */}
      {/* accessRight: {accessRight} */}
      {/* <ReactJson
        src={userRole}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* linkPath: {linkPath} */}
      {/* <>sort: {JSON.stringify(sort)}</> */}
      {/* <ReactJson
        src={preQuery}
        style={{ background: "white" }}
        collapsed
      ></ReactJson>
      <ReactJson
        src={tableSchema.preQuery}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* <ReactJson
        src={formAction.getValues()}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* searchText : {searchText} */}
      {/* <ReactJson
        src={tableFilterQuery}
        style={{ background: "white" }}
        collapsed
      ></ReactJson>
      <ReactJson
        src={rows}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* <ReactJson
        src={tQueryStorage}
        style={{ background: "white" }}
        collapsed
      ></ReactJson>
      <ReactJson
        src={tableFilterQuery}
        style={{ background: "white" }}
        collapsed
      ></ReactJson> */}
      {/* Page : {currentPage} */}
      {/* apiEntity: {apiEntity} */}
      <div>
        {showTable() && header && <header.component {...header.props} />}
        {hideActionButton ? null : (
          <PageListHeader
            title={getTitle()}
            actionButtons={pageActionButtons()}
          />
        )}
        {showTable() && stats && <stats.component {...stats.props} />}
        {showTable() && filter && <filter.component {...filter.props} />}
        {/* <Space h={"xl"}/> */}

        {showTable() && (
          <DataTable
            ref={tableRef}
            data={rows}
            columns={tableSchema.columns}
            pagination={pagination}
            hideSearchBar={hideSearchBar}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortChange={onSortChange}
            onSearchChange={onSearchChange}
            onFilterChange={onTableFilterChange}
            tableFilterQuery={tableFilterQuery}
            onActionBtnClick={onActionBtnClick}
            searchText={searchText}
            padding={tablePadding}
            tableHeader={tableHeader}
          />
        )}
        {/*Import Modal */}
        <Modal
          opened={openedImport}
          onClose={() => setOpenedImport(false)}
          title="Import Data"
          size={"xl"}
        >
          <FileInput
            placeholder="Pick file"
            label="Data File"
            withAsterisk
            onChange={setImportFile}
            disabled={importing}
          />
          <Button onClick={handleImport} mt="xl" size="xs" loading={importing}>
            Import
          </Button>
        </Modal>
        {renderForm()}
      </div>
    </Page>
  );
}

export const AddActionButton = ({
  handleActionClick,
  justify = "right",
  mb = "xl",
  label = "Add",
  mt = "0",
}) => {
  const { t } = useTranslation();
  return (
    <Group justify={justify} mb={mb} mt={mt}>
      <Button size="xs" variant="default" onClick={() => handleActionClick()}>
        <Group>
          <IconCirclePlus size={"1rem"} />
          {t(label)}
        </Group>
      </Button>
      {/* <UnstyledButton onClick={() => handleActionClick()} ml="md">
        <Group gap={"xs"}>
          <ActionIcon>
            <IconCirclePlus size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>Add</Text>
        </Group>
      </UnstyledButton> */}
    </Group>
  );
};
