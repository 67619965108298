import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  const total = charges.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const DebitNoteCostItems = ({ form, name, allowGetRate = false }) => {
  const costItems = _.get(form.values, "costItems");
  const chargeItems = _.get(form.values, "chargeItems");
  // const commissionRate = _.get(form.values, "commissionRate")
  const forceUpdate = useForceUpdate();

  const [api] = useServerApi();

  const [total, setTotal] = useState(0);
  const [commissionRate, setCommissionRate] = useState(
    _.get(form.values, "commissionRate")
  );

  const [formatter] = useFormatter();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: TextInput,
      props: {},
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    {
      header: "Currency",
      headerWidth: "12%",
      fieldName: "currency",
      component: Select,
      props: {
        data: DATA_CURRENCY,
        defaultValue: "HKD",
        searchable: true,
      },
    },
    {
      header: "Unit Price",
      headerWidth: "30%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        parser: formatter.currencyParser,
        formatter: formatter.currencyFormat,
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(costItems);
  }, [costItems]);

  useEffect(() => {
    form.setFieldValue("costTotal", total);
  }, [total]);

  //Just for GI , just get the first row.
  const getCommissionRate = async (id) => {
    if (!id) return;

    try {
      const table = await api.Product.getCurrentCommissionRateTable({
        id,
        date: form.values.date,
      });

      if (!table || !table.commissionVariation) return;

      const { rows } = table.commissionVariation;
      const row = rows[0];
      if (!row || !row.yr1) return;
      // console.log("getCommissionRate", rows, row.yr1);
      setCommissionRate(row.yr1);
      form.setFieldValue("commissionRate", row.yr1);
      forceUpdate();

      // console.log("getCommissionRate", row.yr1);
    } catch (error) {
      console.log(error);
    }
  };

  const applyCommissionRate = () => {
    let cr = commissionRate || _.get(form.values, "commissionRate");
    const chargeItems = _.get(form.values, "chargeItems");
    // console.log("commissionRate", commissionRate, chargeItems);
    const newCostItems = _.cloneDeep(
      chargeItems.filter((item) => item.unitPrice >= 0)
    );

    const pItem = newCostItems.find((c) => c.item === "Premium");
    if (pItem) {
      let unitPrice = pItem.unitPrice * (1 - cr / 100);
      pItem.price = unitPrice * pItem.qty;
      pItem.unitPrice = unitPrice;
      pItem.item = "Net Premium";
    }
    form.setFieldValue("costItems", newCostItems);
    form.setFieldValue("commissionRate", cr);
    // console.log("costItem", costItem, commissionRate);

    forceUpdate();
  };

  const { t } = useTranslation();
  return (
    <>
      {/* <ReactJson src={form.values.product} style={{ background: "white" }} /> */}
      <Group align={"end"} mb="xl">
        <NumberInput
          precision={2}
          label="Commission Rate(%)"
          placeholder="30"
          value={_.get(form.values, "commissionRate")}
          onChange={setCommissionRate}
        ></NumberInput>

        {allowGetRate && form.values.product && (
          <Button
            size="xs"
            onClick={() => getCommissionRate(form.values.product._id)}
          >
            {t("Get Rate")}
          </Button>
        )}
        <Button size="xs" color="green" onClick={() => applyCommissionRate()}>
          {t("Apply Rate")}
        </Button>
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group position="right">
        <Group>
          <Title order={3}>{formatter.currency(total)}</Title>
        </Group>
      </Group>
    </>
  );
};

export default DebitNoteCostItems;
