import { PageList } from "../../components/pageList";
import React from "react";

import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconSettings } from "@tabler/icons";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { useCellRender } from "../../hooks/useCellRender";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog.js";
import _ from "lodash";
import { TextInput, NumberInput, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import { useFormatter } from "../../hooks/useFomatter";

import CreditNotePageStats from "../../components/creditNotePageStats";
import CreditNotePaymentSummary from "../../components/creditNotePaymentSummary";
import CreditNoteRelatedDocumentSection from "../../components/creditNoteRelatedDocumentSection.js";

import QuotationDescription from "../../components/quotationDescription";
import QuotationTerms from "../../components/quotationTerm";

import CreditNoteActionList from "../../components/creditNoteActionList";
import OrderInsuredList from "../../components/orderInsuredList";
import OrderAddressSelect from "../../components/orderAddressSelect";

import OrderConsultantList from "../../components/orderConsultantList";
import OrderApplicantDetail from "../../components/orderApplicantDetail";
import CreditNoteSummary from "../../components/creditNoteSummary";
import FormConfirmation from "../../components/formConfirmation";
import QuoFileList from "../../components/quofileList";
import CreditNotePolicyDetailPreview from "../../components/creditNotePolicyDetailPreview";
import CreditNoteRefundItems from "../../components/creditNoteRefundItems";
import CreditNoteRefundSummary from "../../components/creditNoteRefundSummary";

const apiEntity = "creditNote";

const initialValues = {
  date: moment().toISOString(),
  status: "DFT",
  refundItems: [
    {
      item: "Premium Refund",
      currency: "HKD",
      qty: 1,
      unitPrice: 0,
      price: 0,
    },
    { item: "MIB", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
    { item: "IA Levy", currency: "HKD", qty: 1, unitPrice: 0, price: 0 },
  ],
};

const CreditNote = ({}) => {
  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "CR No.",
        sortable: true,
        cellRender: (col, data) => cellRender.CreditNote.code(data),
      },
      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      {
        field: "policyNumber",
        headerName: "Policy Number",
        sortable: true,
      },
      {
        field: "order.code",
        headerName: "Order No.",
        sortable: true,
      },

      {
        field: "client",
        headerName: "Client",
        sortable: true,
        cellRender: (col, data) => cellRender.ClientInfo(data.client),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconTrash,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };

  const addFormSchema = {
    name: "creditNoteSchema",
    title: "Create Credit Note",
    description: "Please follow the step to create a New Credit Note",
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
    },
    apiEntity,
    showSaveBar: false,

    initialValues,
    validate: {
      policyNumber: (value) =>
        !value ? "Please enter the policy number" : null,
      order: (value) =>
        !value ? "Please select Order with policy number" : null,
    },

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,

          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Policy",
            orientation: "vertical",
            style: { minHeight: "400px" },
          },
          steps: [
            { key: "t1", label: "Policy", icon: IconSettings },
            { key: "t2", label: "CN Details", icon: IconSettings },
            { key: "t4", label: "Confirmation ", icon: IconSettings },
          ],
        },
      ],
      fields: [
        {
          name: "policyNumber",
          component: TextInput,
          parent: "t1",
          props: {
            required: true,
            label: "Policy Number",
            showProductSnapShot: false,
          },
        },
        {
          name: "detail",
          component: CreditNotePolicyDetailPreview,
          parent: "t1",
          props: {},
        },

        {
          name: "date",
          component: DatePicker,
          type: "datePicker",
          parent: "t2",
          props: {
            label: "Credit Note Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },

        {
          name: "payDate",
          component: DatePicker,
          type: "datePicker",
          parent: "t2",
          props: {
            label: "Provider Pay Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "totalCreditToClient",
          component: NumberInput,
          parent: "t2",
          props: {
            label: "Total Credit to Client",
            required: true,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
            precision: 2,
          },
        },
        {
          name: "totalCreditToBroker",
          component: NumberInput,
          parent: "t2",
          props: {
            label: "Total Credit to Broker",
            required: true,
            parser: formatter.currencyParser,
            formatter: formatter.currencyFormat,
            precision: 2,
          },
        },

        {
          name: "remark",
          component: Textarea,
          parent: "t2",
          props: {
            label: "Remark",
          },
        },
        {
          name: "confirm",
          component: FormConfirmation,
          parent: "t4",
          props: {
            successText: [
              "Please confirm only if the provided information is correct.",
            ],
          },
        },
      ],
    },
  };

  const editFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.LINK,
    },
    title: "Credit Note",
    titleField: "code",
    apiEntity,
    initialValues,
    layout: {
      containers: [
        {
          key: "main-g",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          props: {
            gutter: "xl",
            columns: 24,
          },
          cols: [
            {
              key: "main-g1",
              props: {
                span: 7,
              },
            },
            {
              key: "main-g2",
              props: {
                span: 17,
              },
            },
          ],
        },

        {
          key: "main-tab",
          parent: "main-g2",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Overview",
          },
          tab: [
            {
              key: "tab-overview",
              label: "Overview",
              //icon: IconSettings,
            },
            {
              key: "tab-insured",
              label: "Insured",
              //icon: IconSettings,
            },
            {
              key: "tab-files",
              label: "Files",
              //icon: IconSettings,
            },
            {
              key: "tab-logs",
              label: "Logs",
              // icon: IconSettings
            },
          ],
        },
        {
          key: "section-applicant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Applicant Details",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-consultant",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
          props: {
            mt: 0,
            mb: 0,
          },
        },

        {
          key: "section-summary",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Summary",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-payment",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Payment Info",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-relatedDocument",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Related Documents",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-address",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Order Address",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-insured",
          parent: "tab-insured",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Insured Details",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: 0,

            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-quo-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "File List",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "section-files",
          parent: "tab-files",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Files",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 20,
            mb: 20,
          },
        },

        {
          key: "main-tab",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Descriptions",
          },
          tab: [
            {
              key: "tab-descriptions",
              label: "Descriptions",
              //icon: IconSettings,
            },
            {
              key: "tab-terms",
              label: "Terms",
              //icon: IconSettings,
            },
          ],
        },
        {
          key: "tab-payment",
          parent: "tab-overview",
          type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
          props: {
            variant: "default", //default | pills | outline
            defaultValue: "Refund Items",
          },
          tab: [
            {
              key: "tab-refundItems",
              label: "Refund Items",
              //icon: IconSettings,
            },
          ],
        },
        {
          key: "section-description",
          parent: "tab-descriptions",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Description",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        {
          key: "section-term",
          parent: "tab-terms",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },
        {
          key: "section-refund",
          parent: "tab-refundItems",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Charge Items",
            withBorder: false,
            mt: 0,
            mb: "md",
          },
        },

        // {
        // 	key: "section-payments",
        // 	parent: "tab-overview",
        // 	type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        // 	props: {
        // 		title: "Payments",
        // 		withBorder: false,
        // 		mt: 0,
        // 		mb: "md",
        // 	},
        // },
        {
          key: "section-log",
          parent: "tab-logs",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            // title: "Logs",
            titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            mt: 20,
            mb: 20,
          },
        },
        {
          key: "section-action-list",
          parent: "main-g1",
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Action List",
            // titleOrder: 5,
            // description: "Please assign the corresponding consultant",
            withBorder: false,
            minHeight: "0px",
            mt: 0,
            mb: 20,
          },
        },
      ],
      fields: [
        {
          name: "client",
          component: OrderApplicantDetail,
          parent: "section-applicant",
          props: {},
        },
        {
          name: "consultants",
          component: OrderConsultantList,
          parent: "section-consultant",
          props: {},
        },
        {
          name: "actions",
          component: CreditNoteActionList,
          parent: "section-action-list",
          props: {},
        },

        {
          name: "summary",
          component: CreditNoteSummary,
          parent: "section-summary",
          props: {},
        },
        {
          name: "summary",
          component: CreditNotePaymentSummary,
          parent: "section-payment",
          props: {},
        },
        {
          name: "relatedDocuments",
          component: CreditNoteRelatedDocumentSection,
          parent: "section-relatedDocument",
          props: {},
        },

        {
          name: "insured",
          component: OrderInsuredList,
          parent: "section-insured",
          props: {},
        },

        {
          name: "address",
          component: OrderAddressSelect,
          parent: "section-address",
          props: {},
        },

        {
          name: "descriptions",
          component: QuotationDescription,
          parent: "section-description",
          props: {
            required: true,
          },
        },

        {
          name: "terms",
          component: QuotationTerms,
          parent: "section-term",
          props: {
            required: true,
          },
        },

        {
          name: "refundItems",
          component: CreditNoteRefundItems,
          parent: "section-refund",
          props: {
            required: true,
          },
        },

        {
          name: "summary",
          component: CreditNoteRefundSummary,
          parent: "section-refund",
          props: {
            required: true,
          },
        },
        {
          name: "files",
          component: FileList,
          parent: "section-files",
          props: {},
        },

        {
          name: "fileList",
          component: QuoFileList,
          parent: "section-quo-files",
          props: {
            // title: "Please upload related files.",
          },
        },

        {
          name: "log",
          component: ActivityLog,
          parent: "section-log",
          props: {},
        },
      ],
    },
  };

  const formSchema = {
    add: addFormSchema,
    edit: editFormSchema,
  };

  return (
    <PageList
      title={"Credit Note"}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      searchByTextField={true}
      stats={{
        component: CreditNotePageStats,
        props: {
          mb: 35,
          role: "OPERATION",
        },
      }}
    />
  );
};

export default CreditNote;
