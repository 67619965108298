import React from "react";
import { Button, Textarea, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";
import { ENUM_FORM_DISPLAY } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import _ from "lodash";

const RenewalDeliveryButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const { t } = useTranslation();

  const delivery = _.get(mainForm.values, "delivery");
  if (!delivery) {
    _.set(mainForm.values, "delivery", {});
  }

  const schema = {
    title: "Renewal Delivery",
    description: "Please fill in the delivery details",
    initialValues: delivery,
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },

    layout: {
      containers: [],

      fields: [
        {
          name: "delivery.date",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Delivery Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },
        {
          name: "delivery.remark",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      values.status = "WAIT";
      mainForm.updateAndSave(values);
      formAction.close();
    } catch (error) {
      console.log(error);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    schema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        {t("Deliver Renewal")}
      </Button>
      {renderForm()}
    </>
  );
};

export default RenewalDeliveryButton;
