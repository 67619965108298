import {
  Navbar,
  ScrollArea,
  createStyles,
  Code,
  UnstyledButton,
  Badge,
  Text,
  Group,
  ActionIcon,
  Tooltip,
  TextInput,
  useMantineColorScheme,
  useMantineTheme,
  Switch,
  Header,
  MediaQuery,
  Title,
  Burger,
} from "@mantine/core";

import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconBulb,
  IconUser,
  IconCheckbox,
  IconSearch,
  IconPlus,
  IconSelector,
  IconDashboard,
  IconBrandGoogleDrive,
  IconFileSearch,
  IconFileText,
  IconLogout,
  IconSun,
  IconMoonStars,
  IconSettings,
  IconMessage,
  IconHome,
  IconUsers,
  IconFileInvoice,
  IconBasket,
  IconWallet,
  IconCashBanknote,
  IconCash,
  IconHierarchy,
  IconHierarchy2,
  IconHierarchy3,
  IconUserCircle,
  IconCategory2,
  IconCategory,
  IconBuildingBank,
  IconBuildingSkyscraper,
  IconUserExclamation,
  IconCircuitGround,
  IconCircleLetterG,
  IconComponents,
  IconListDetails,
  IconExchange,
  IconTicket,
  IconRating12Plus,
  IconNumber,
  IconCalendarEvent,
  IconFileImport,
  IconGiftCard,
  IconPlane,
  IconGif,
  IconGift,
  IconMoneybag,
  IconPrompt,
  IconStar,
  IconLayoutDashboard,
  IconSettings2,
  IconQuote,
  IconCircleLetterB,
  IconLetterR,
  IconRefresh,
  IconDatabase,
  IconDatabaseImport,
  IconReport,
  IconGlassFull,
  IconLetterS,
  IconAlertTriangle,
  IconBlockquote,
} from "@tabler/icons";

export const mainLinks = [
  // { icon: IconHome, label: "Home", link: "/home" },
  // { icon: IconDashboard, label: "Dashboard", link: "/dashboard" },
  // { icon: IconFileSearch, label: "File Manager" },
  // { icon: IconFileText, label: "Knowledgebase" },
  // {
  // 	icon: IconMessage,
  // 	label: "My Tickets",
  // 	link: "/chat",
  // 	notifications: 0,
  // },
];

export const functionLinks = [
  {
    icon: IconHome,
    label: "Home",
    link: "/home",
    linkId: "1",
    showOnNavBar: true,
  },

  {
    icon: IconLayoutDashboard,
    label: "Dashboard",
    link: "/dashboard",
    linkId: "2",
    showOnNavBar: true,
  },

  // {
  //   icon: IconFileSearch,
  //   label: "File Manager",
  //   linkId: "3",
  //   link: "/fileManagers",
  //   showOnNavBar: true,
  // },

  {
    icon: IconMessage,
    label: "My Tickets",
    link: "/chat",
    linkId: "5",
    notifications: 0,
    showOnNavBar: true,
    showDivider: true,
  },

  {
    icon: IconMessage,
    label: "Ticket",
    link: "/ticket",
    linkId: "T1",
    showDivider: true,
    showOnNavBar: true,
  },

  //Operations
  {
    icon: IconUsers,
    label: "Client",
    link: "/operation/client",
    linkId: "O1",
    showOnNavBar: true,
    group: "Operation",
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/operation/order",
    linkId: "O2",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconLetterS,
    label: "Order Service",
    link: "/operation/orderService",
    linkId: "O12",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconBasket,
    label: "Product",
    link: "/operation/product",
    linkId: "O3",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconBuildingSkyscraper,
    label: "Provider",
    link: "/operation/provider",
    linkId: "O6",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconComponents,
    label: "Product Cateogry",
    link: "/operation/productCategory",
    linkId: "O4",
    group: "Operation",
    showOnNavBar: false,
  },
  {
    icon: IconCategory,
    label: "Product Type",
    link: "/operation/productType",
    linkId: "O5",
    group: "Operation",
    showOnNavBar: false,
  },

  {
    icon: IconUserCircle,
    label: "User",
    link: "/operation/user",
    linkId: "O7",
    group: "Operation",
    showOnNavBar: true,
  },

  {
    icon: IconCircleLetterG,
    label: "User Group",
    link: "/operation/userGroup",
    linkId: "O8",
    group: "Operation",
    showOnNavBar: false,
  },

  {
    icon: IconSettings2,
    label: "Settings",
    link: "/o/setting",
    linkId: "O9",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconFileText,
    label: "CPD Records",
    link: "/operation/cpd",
    linkId: "O10",
    group: "Operation",
    showOnNavBar: true,
  },
  {
    icon: IconDatabaseImport,
    label: "DataFeed",
    group: "Operation",
    link: "/datafeed",
    linkId: "O11",
    showOnNavBar: true,
  },
  //   {
  //     icon: IconReport,
  //     label: "Report",
  //     link: "/report",
  //     linkId: "R1",
  //     group: "Operation",

  //     showDivider: true,
  //     showOnNavBar: true,
  //   },

  //GI Operations
  {
    icon: IconBlockquote,
    label: "Quo Request",
    link: "/gi/quoRequest",
    linkId: "GIQUOREQ",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconQuote,
    label: "Quotation",
    link: "/gi/Quotation",
    linkId: "GIQuo",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconQuote,
    label: "Renewal",
    link: "/gi/renewal",
    linkId: "GIQuo",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconNotes,
    label: "Debit Notes",
    link: "/gi/debitNote",
    linkId: "GIDEB",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconNotes,
    label: "Credit Notes",
    link: "/gi/creditNote",
    linkId: "GIDEB",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconUsers,
    label: "Client",
    link: "/gi/client",
    linkId: "GI1",
    showOnNavBar: true,
    group: "GI Operation",
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/gi/order",
    linkId: "GI2",
    group: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconBasket,
    label: "Product",
    link: "/operation/product",
    linkId: "GI3",
    group: "GI Operation",

    showOnNavBar: true,
  },
  {
    icon: IconBuildingSkyscraper,
    label: "Provider",
    link: "/operation/provider",
    linkId: "GI6",
    grgroup: "GI Operation",
    showOnNavBar: true,
  },
  {
    icon: IconComponents,
    label: "Product Cateogry",
    link: "/operation/productCategory",
    linkId: "GI4",
    group: "GI Operation",
    showOnNavBar: false,
  },
  {
    icon: IconCategory,
    label: "Product Type",
    link: "/operation/productType",
    linkId: "GI5",
    grgroup: "GI Operation",
    showOnNavBar: false,
  },

  {
    icon: IconUserCircle,
    label: "User",
    link: "/operation/user",
    linkId: "GI7",
    grgroup: "GI Operation",
    showOnNavBar: true,
  },

  {
    icon: IconCircleLetterG,
    label: "User Group",
    link: "/operation/userGroup",
    linkId: "GI8",
    grgroup: "GI Operation",
    showOnNavBar: false,
  },
  {
    icon: IconSettings2,
    label: "Settings",
    link: "/o/setting",
    linkId: "GI9",
    grgroup: "GI Operation",
    showOnNavBar: true,
  },

  //Management Links
  {
    icon: IconUsers,
    label: "Client",
    link: "/management/client",
    linkId: "M101",
    showOnNavBar: true,
    group: "Management",
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/management/order",
    linkId: "M102",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconLetterS,
    label: "Order Service",
    link: "/management/orderService",
    linkId: "M110",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconBasket,
    label: "Product",
    link: "/management/product",
    linkId: "M103",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconBuildingSkyscraper,
    label: "Provider",
    link: "/management/provider",
    linkId: "M11",
    group: "Management",
    showOnNavBar: true,
  },

  {
    icon: IconUserCircle,
    label: "User",
    link: "/management/user",
    linkId: "M107",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconGiftCard,
    label: "Allowance",
    link: "/management/allowance",
    linkId: "M1",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconGift,
    label: "Allowance Plan",
    link: "/management/allowancePlan",
    linkId: "M2",
    group: "Management",
    showOnNavBar: false,
  },
  // {
  //   icon: IconBlockquote,
  //   label: "Quo Request",
  //   link: "/m/gi/quoRequest",
  //   linkId: "GIQUOREQ-M",
  //   group: "Management",
  //   showOnNavBar: true,
  // },
  {
    icon: IconQuote,
    label: "GI Quotation",
    link: "/m/gi/Quotation",
    linkId: "GIQuo-M",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconQuote,
    label: "GI Renewal",
    link: "/m/gi/renewal",
    linkId: "GIQuo-M",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconNotes,
    label: "GI Debit Notes",
    link: "/m/gi/debitNote",
    linkId: "GIDEB-M",
    group: "Management",
    showOnNavBar: true,
  },
  // {
  //   icon: IconNotes,
  //   label: "GI Match Payment",
  //   link: "/m/gi/matchPayment",
  //   linkId: "GIDEB-M",
  //   group: "Management",
  //   showOnNavBar: true,
  // },
  {
    icon: IconNotes,
    label: "GI Settlement",
    link: "/m/gi/settlement",
    linkId: "GIDEB-M",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconNotes,
    label: "GI Credit Notes",
    link: "/m/gi/creditNote",
    linkId: "GIDEB-M",
    group: "Management",
    showOnNavBar: true,
  },

  // {
  //   icon: IconNotes,
  //   label: "Bank Transaction",
  //   link: "/management/bankTransaction",
  //   linkId: "M18",
  //   group: "Management",
  //   showOnNavBar: true,
  // },

  {
    icon: IconCircleLetterB,
    label: "Bank Account",
    link: "/management/bankAccount",
    linkId: "M26",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconCircleLetterB,
    label: "Bank Account Detail",
    link: "/m/bankAccountDetail",
    linkId: "M27",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconCalendarEvent,
    label: "Calendar Events",
    link: "/management/calendarEvent",
    linkId: "M3",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconStar,
    label: "Client Promotion",
    link: "/management/clientPromotion",
    linkId: "M5",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconCategory2,
    label: "Product Cateogry",
    link: "/operation/productCategory",
    linkId: "M8",
    group: "Management",
    showOnNavBar: false,
  },
  {
    icon: IconMoneybag,
    label: "Consultant Incentive",
    link: "/management/consultantIncentive",
    linkId: "M9",
    group: "Management",
    showOnNavBar: false,
  },
  {
    icon: IconCategory,
    label: "Product Type",
    link: "/operation/productType",
    linkId: "M10",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconCircleLetterG,
    label: "User Group",
    link: "/operation/userGroup",
    linkId: "M13",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconDashboard,
    label: "Overriding Plan",
    link: "/management/commissionOverridingPlan",
    linkId: "M14",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconExchange,
    label: "Exchange Rate",
    link: "/management/exchangeRate",
    linkId: "M15",
    group: "Management",
    showOnNavBar: false,
  },
  {
    icon: IconTicket,
    label: "Ticket Types",
    link: "/management/ticketTypes",
    linkId: "M16",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconTicket,
    label: "Order Service Types ",
    link: "/management/orderServiceType",
    linkId: "M25",
    group: "Management",
    showOnNavBar: false,
  },

  {
    icon: IconNumber,
    label: "Top Rankings",
    link: "/management/topRanking",
    linkId: "M17",
    group: "Management",
    showOnNavBar: false,
  },
  {
    icon: IconListDetails,
    label: "Commission Statement",
    link: "/management/commissionStatement",
    linkId: "M18",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconListDetails,
    label: "Incentive",
    link: "/management/incentive",
    linkId: "M109",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconCashBanknote,
    label: "Payroll",
    link: "/management/payroll",
    linkId: "M19",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconFileImport,
    label: "Profolio Import",
    link: "/management/profolioImport",
    linkId: "M20",
    group: "Management",
    showOnNavBar: true,
  },

  {
    icon: IconCircleLetterB,
    label: "Bids",
    link: "/management/bids",
    linkId: "M22",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconCalendarEvent,
    label: "CPD Records",
    link: "/management/cpd",
    linkId: "M23",
    group: "Management",
    showOnNavBar: true,
  },
  {
    icon: IconSettings2,
    label: "Settings",
    link: "/m/setting",
    linkId: "M21",
    group: "Management",
    showOnNavBar: true,
  },

  {
    icon: IconDatabaseImport,
    label: "DataFeed",
    link: "/management/datafeed",
    group: "Management",
    linkId: "M24",
    showOnNavBar: true,
  },

  {
    icon: IconReport,
    label: "Report",
    link: "/management/report",
    linkId: "R1",
    group: "Management",
    showDivider: true,
    showOnNavBar: true,
  },

  //Account Link
  {
    icon: IconUsers,
    label: "Client",
    link: "/account/client",
    linkId: "A1",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/account/order",
    linkId: "A2",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconBasket,
    label: "Product",
    link: "/account/product",
    linkId: "A3",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconUser,
    label: "User",
    link: "/account/user",
    linkId: "A4",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconGiftCard,
    label: "Allowance",
    link: "/account/allowance",
    linkId: "A10",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconListDetails,
    label: "Commission Statement",
    link: "/account/commissionStatement",
    linkId: "A5",
    group: "Account",
    showOnNavBar: true,
  },

  {
    icon: IconListDetails,
    label: "Incentive",
    link: "/account/incentive",
    linkId: "A9",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconCashBanknote,
    label: "Payroll",
    link: "/account/payroll",
    linkId: "A6",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconCircleLetterB,
    label: "Bids",
    link: "/account/bids",
    linkId: "A7",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconDatabaseImport,
    label: "DataFeed",
    link: "/datafeed",
    group: "Account",
    linkId: "A8",
    showOnNavBar: true,
  },
  {
    icon: IconReport,
    label: "Report",
    link: "/account/report",
    linkId: "RA",
    group: "Account",
    showDivider: true,
    showOnNavBar: true,
  },
  {
    icon: IconCircleLetterB,
    label: "Bank Account",
    link: "/account/bankAccount",
    linkId: "A26",
    group: "Account",
    showOnNavBar: true,
  },
  {
    icon: IconCircleLetterB,
    label: "Bank Account Detail",
    link: "/account/bankAccountDetail",
    linkId: "A27",
    group: "Account",
    showOnNavBar: false,
  },

  //   {
  //     icon: IconReport,
  //     label: "Report",
  //     link: "/report",
  //     linkId: "R1",
  //     group: "Account",

  //     showDivider: true,
  //     showOnNavBar: true,
  //   },

  //Consultant Links
  {
    icon: IconUsers,
    label: "Client",
    link: "/consultant/client",
    linkId: "C1",
    group: "Consultant",
    showOnNavBar: true,
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/consultant/order",
    linkId: "C2",
    group: "Consultant",
    showOnNavBar: true,
  },

  {
    icon: IconAlertTriangle,
    label: "Order Pending",
    link: "/consultant/orderPending",
    linkId: "C9",
    group: "Consultant",
    showOnNavBar: true,
  },
  {
    icon: IconRefresh,
    label: "Order Renewal",
    link: "/consultant/orderRenewal",
    linkId: "C7",
    group: "Consultant",
    showOnNavBar: true,
  },
  // {
  //   icon: IconBlockquote,
  //   label: "Quo Request",
  //   link: "/consultant/quoRequest",
  //   linkId: "QUOREQ",
  //   group: "Consultant",
  //   showOnNavBar: true,
  // },

  {
    icon: IconBasket,
    label: "Product",
    link: "/consultant/product",
    linkId: "C3",
    group: "Consultant",
    showOnNavBar: true,
  },
  {
    icon: IconCalendarEvent,
    label: "My CPD",
    link: "/consultant/myCPD",
    linkId: "C8",
    group: "Consultant",
    showOnNavBar: true,
  },
  {
    icon: IconWallet,
    label: "My Wallet",
    link: "/consultant/wallet",
    linkId: "C4",
    group: "Consultant",
    showOnNavBar: true,
  },
  {
    icon: IconCash,
    label: "My Payroll",
    link: "/consultant/payrollPayment",
    linkId: "C5",
    group: "Consultant",
    showOnNavBar: true,
  },

  {
    icon: IconHierarchy3,
    label: "My Team",
    link: "/consultant/myTeam",
    linkId: "C6",
    group: "Consultant",
    showOnNavBar: true,
  },
];
