import React from "react";
import { Button, Textarea, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";
import { ENUM_FORM_DISPLAY } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import _ from "lodash";

import { DATA_RENEWAL_REJECTION_REASON } from "../data/options";

const RenewalRejectButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const { t } = useTranslation();

  const rejection = _.get(mainForm.values, "rejection");
  if (!rejection) {
    _.set(mainForm.values, "rejection", {});
  }

  const RejectformSchema = {
    title: "Renewal Reject",
    description: "Please fill in the rejection reason",
    initialValues: rejection,
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },

    layout: {
      containers: [],

      fields: [
        {
          name: "rejection.date",
          component: DatePicker,
          type: "datePicker",
          props: {
            required: true,
            label: "Rejection Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
            type: "datePicker",
          },
        },
        {
          name: "rejection.reason",
          component: Select,
          props: {
            label: "Reason",
            placeholder: "Select a reason",
            data: DATA_RENEWAL_REJECTION_REASON,
            searchable: true,
          },
        },
        {
          name: "rejection.remark",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, forMode }) => {
    try {
      console.log("reject", values);
      values.status = "TERMINATE";
      mainForm.updateAndSave(values);
      formAction.close();
    } catch (error) {
      console.log(error);
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    RejectformSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  const terminate = () => {
    mainForm.updateAndSave({ status: "TERMINATE" });
  };

  return (
    <>
      <Button fullWidth color={"red"} onClick={handleActionClick}>
        {t("Reject Renewal")}
      </Button>
      {renderForm()}
    </>
  );
};

export default RenewalRejectButton;
