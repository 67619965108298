import React, { useEffect, useState } from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import AddressList from "./addressList";
import { useCellRender } from "../hooks/useCellRender";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { Button, Group, UnstyledButton, Text, Select } from "@mantine/core";
import { IconBook2, IconRefresh, IconReload } from "@tabler/icons";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/userServerApi";
import { useAccessRight } from "../hooks/useAccessRight";

export default function OrderAddressSelect({ form: mainForm }) {
  const client = _.get(mainForm.values, "client");
  let address = _.get(mainForm.values, "address");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();

  const [addressOptions, setAddressOptions] = useState([]);
  const [getAccessRight, accessRight] = useAccessRight();
  const disabled = accessRight !== 15;

  const getAddressOptions = (c) => {
    const options = c.addresses?.map((add) => {
      let arr = [
        add.line1,
        add.line2,
        add.city,
        add.state,
        add.zip,
        add.country,
      ]
        .map((a) => _.replace(a, ",", ""))
        .filter((a) => !_.isEmpty(a?.trim()));
      let label = _.join(arr, " ");

      return { label, value: add };
    });
    return options;
  };

  useDeepCompareEffect(() => {
    if (!address && client) {
      if (!_.isEmpty(client.addresses)) {
        address = client.addresses[client.addresses.length - 1];
        mainForm.setFieldValue("address", address);
      }
    }
    if (client && client.addresses) {
      setAddressOptions(getAddressOptions(client));
    }
  }, [client]);

  const changeAddress = (v) => {
    // console.log("Change address", v)
    mainForm.setFieldValue("address", v);
  };

  const formSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "800px",
    },
    layout: {
      containers: [
        {
          key: "section-address",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            title: "Address",
            titleOrder: 3,
            description: "Please fill in the address info",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
      ],
      fields: [
        {
          name: "addresses",
          component: AddressList,
          parent: "section-address",
          props: {},
        },
      ],
    },
  };

  const onSubmitSucess = () => {};

  const updateClientAddress = async (client) => {
    try {
      const result = await api.updateById({
        apiEntity: "client",
        values: { addresses: client.addresses },
        id: client._id,
      });
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = ({ values: client, formMode }) => {
    //Update client addresses
    if (!client) return;
    formAction.close();
    updateClientAddress(client);
    mainForm.setFieldValue("client", client);
    setAddressOptions(getAddressOptions(client));
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSucess,
    onSubmit
  );

  const openAddressBook = () => {
    formAction.open({ mode: "edit", data: client });
  };

  return (
    <>
      {/* <ReactJson src={client?.addresses} collapsed style={{ background: "white" }} />
        <ReactJson src={address} collapsed style={{ background: "white" }} /> */}
      {/* <ReactJson src={address} collapsed style={{ background: "white" }} /> */}
      {/* accessRight: {accessRight} */}
      {!disabled && (
        <Select size="xs" data={addressOptions} onChange={changeAddress} />
      )}
      {!disabled && (
        <UnstyledButton onClick={() => openAddressBook()} mt="sm" mb="xl">
          <Group spacing={"xs"}>
            <IconBook2 size={15} />
            <Text size="xs">Address Book</Text>
          </Group>
        </UnstyledButton>
      )}
      {cellRender.Address(address, "xs")}
      {renderForm()}
    </>
  );
}
