import { Button, Group } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";

import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

import QuotationConfirmButton from "./quotationConfirmButton";
import QuotationCopyDNButton from "./quotationCopyDNButton";
import QuotationEditButton from "./quotationEditButton";
import RenewalConfirmButton from "./renewalConfirmButton";
import RenewalCopyDNButton from "./renewalCopyDNButton";

import RenewalRejectButton from "./renewalRejectButton";

import PrintCancelNoticeButton from "./renewalPrintCancelNoticeButton";

import RenewalDeliveryButton from "./renewalDeliveryButton"; // Add this import

const ORDER_STATUS_ALLOWED_TO_EDIT = ["DFT", "CAN", "RCV", "SUB", "PND", "AIP"];
const ORDER_STATUS_ALLOWED_TO_ADJUST = ["INF"];
const ORDER_STATUS_ALLOWED_TO_CONFIRM = ["SUB", "PND", "AIP"];

const PrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Renewal.print(_id, "RN");
      setPrinting(false);

      // console.log(result);
      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      mainForm.setFieldValue("status", result.data.status);
    } catch (error) {
      console.log(error);
      setPrinting(false);
    }
  };

  return (
    <Button fullWidth onClick={() => print()} loading={printing}>
      {printing ? "Printing" : "Print Renewal Notice"}
    </Button>
  );
};

const RenewalActionList = ({ form, role }) => {
  const { status, code, consultants, _id } = form.values;

  const showConfirm = true;
  const showPrint = true;
  const showTerminate = status !== "TERMINATE";
  const showDelivery = status !== "TERMINATE";
  const showCopy = true;
  const showEdit = true;
  const showPrintCancel = status === "TERMINATE";

  return (
    <>
      {status && (
        <Group mt={10}>
          {showPrint && <PrintButton mainForm={form} />}
          {showConfirm && <RenewalConfirmButton mainForm={form} />}
          {showDelivery && <RenewalDeliveryButton mainForm={form} />}
          {/* {showCopy && (
          <RenewalCopyDNButton mainForm={form} title="Copy to Debit Note" />
        )} */}
          {showPrintCancel && <PrintCancelNoticeButton mainForm={form} />}
          {showTerminate && <RenewalRejectButton mainForm={form} />}
        </Group>
      )}
    </>
  );
};

export default RenewalActionList;
